<template>
  <ul v-if="errors && errors.length > 0">
    <li v-for="(error, index) in errors" :key="`err-${index}`">
      {{ error.message }}
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
